export default [
    {
        path: '/nutrition',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                path: '',
                meta: {
                    title: '营养查询',
                    showNavigation: true,
                    showTab: false
                },
                component: () => import('@/page/nutrition/index')
            },
            {
                path: 'report',
                meta: { title: '个人营养报告' },
                component: () => import('@/page/nutrition/report')
            }
        ]
    }
]
