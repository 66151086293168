export default [
    {
        path: '/page/navi',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                meta: {
                    showBackButton: true,
                    title: '充值',
                    navigationStyle: 'background-color:#ff7500'
                },
                path: 'charge/charge',
                component: () => import('@/page/navi/charge')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '支付',
                },
                path: 'charge/selectchannel',
                component: () => import('@/page/navi/charge/selectChannel.vue')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '支付成功'
                },
                path: 'charge/result/result',
                component: () => import('@/page/navi/charge/result')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '卡包'
                },
                path: 'card/card',
                component: () => import('@/page/navi/card')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '代金券'
                },
                path: 'coupon/coupon',
                component: () => import('@/page/navi/coupon')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '代金券'
                },
                path: 'coupon/detail/detail:id',
                component: () => import('@/page/navi/coupon/detail')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '客餐申请记录'
                },
                path: 'guest/voucher',
                component: () => import('@/page/navi/voucher')
            },
            {
                meta: {
                    showBackButton: true,
                    title: ''
                },
                path: 'guest/detail/detail:id',
                component: () => import('@/page/navi/voucher/detail')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '付款码',
                    navigationStyle: 'background-color:rgba(0,0,0,0);z-index:1'
                },
                path: 'pay/qrcode/qrcode',
                component: () => import('@/page/navi/pay/pay')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '企业码',
                    navigationStyle: 'background-color:rgba(0,0,0,0);z-index:1'
                },
                path: 'pay/enterpriseQrcode/enterpriseQrcode',
                component: () => import('@/page/navi/pay/enterprisePay')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '支付结果'
                },
                path: 'pay/result/result:id',
                component: () => import('@/page/navi/pay/result')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '账单'
                },
                path: 'bill/bill',
                component: () => import('@/page/navi/bill')
            },
            {
                meta: {
                    showBackButton: true,
                    title: ''
                },
                path: 'components/bill/detail/detail',
                component: () => import('@/page/navi/components/bill/detail')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '餐呗'
                },
                path: 'creditbill/creditbill',
                component: () => import('@/page/navi/creditbill')
            },
            {
                meta: {
                    showBackButton: false,
                    showTab: true,
                    title: '消息盒子'
                },
                path: 'message/message:id',
                component: () => import('@/page/navi/message')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '重要通知'
                },
                path: 'message/notice/detail:id',
                component: () =>
                    import('@/page/navi/message/noticDetail/detail.vue')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '调查'
                },
                path: 'message/notice/question:id',
                component: () =>
                    import('@/page/navi/message/noticDetail/question.vue')
            },
            {
                meta: {
                    showNavigation: false
                },
                path: 'home/home',
                component: () => import('@/page/navi/home')
            },
            {
                meta: {
                    showBackButton: true,
                    title: '修改信息'
                },
                path: 'home/userinfo/userinfo',
                component: () => import('@/page/navi/home/userinfo')
            }
        ]
    }
]
