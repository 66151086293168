import moment from 'moment'
import perfect from '@/library/request/perfect'
import { keyMerge } from '@/library/wrench'

const addDatum = ({
    memberid,
    customerId,
    allinvoice,
    amt,
    amt_dues,
    order
}) => {
    return perfect({
        url: 'v1/ordering/bill_create',
        data: {
            params: {
                memberid,
                customerId,
                allinvoice,
                amt,
                amt_dues,
                order: JSON.stringify(order)
            }
        },
        transformResponse: [
            (response) => {
                response = JSON.parse(response)

                const { code, msg, seq } = response.data || {}

                return {
                    code:
                        response.success && code === 1
                            ? 1
                            : response.errCode || code,
                    list: [],
                    total: 0,
                    info: {
                        seq
                    },
                    msg: response.errMessage || msg
                }
            }
        ]
    }).then((response) => response.info)
}

const getDetail = ({ seq, t }) => {
    return perfect({
        url: 'v1/order/detail',
        data: {
            params: {
                invoice: seq,
                t: t || 2
            }
        },
        transformResponse: [
            (response) => {
                response = JSON.parse(response)
                const data = response.data || {}
                const standard = {
                    code:
                        response.success && data.code === 1
                            ? 1
                            : response.errCode || data.code,
                    list: [],
                    total: 0,
                    info: null,
                    msg: response.errMessage || data.msg
                }

                delete data.code
                delete data.msg
                delete data.total

                standard.info = { ...data }

                return standard
            }
        ]
    }).then((response) => {
        const {
            info,
            payments,
            details,
            stall,
            returns,
            policyAmount,
            walletAmount,
            subsidyCouponAmount,
            chargeCouponAmount,
            enterpriseCodeAmount,
            creditLimitAmount,
            policyRefundAmount,
            walletRefundAmount,
            subsidyCouponRefundAmount,
            chargeCouponRefundAmount,
            enterpriseCodeRefundAmount,
            creditLimitRefundAmount
        } = response.info
        const timestamp = moment().valueOf()

        info.original_seq = seq // 存储调用接口传入时的seq。
        info.amt_dues_name = info.amt_dues.toFixed(2)
        info.discount_name = info.discount.toFixed(2)
        info.service_name = info.service.toFixed(2)
        info.delivery_name = info.delivery.toFixed(2)
        info.deposit_name = info.deposit.toFixed(2)
        info.balance_name = info.surplus.toFixed(2)
        info.amt_name = info.amt.toFixed(2)
        info.type_value = info.type % 1000
        info.properties = info.properties ? JSON.parse(info.properties) : {}
        info.use_date = info.use_date
            .toString()
            .replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3')
        info.can_cancel =
            moment(
                info.properties.cancel_time,
                'YYYY-MM-DD HH:mm:ss'
            ).valueOf() >= timestamp
        info.remark = info.remark ? JSON.parse(info.remark) : {}
        info.delivery_address = (info.properties.take_address || [])
            .map((item) => item.name)
            .join('')
        info.refund_amount =
            info.type_value === 13 && info.state === 1 ? info.deposit : info.amt
        info.stalladdress = info.stalladdress || stall.addr

        response.info.info = {
            ...info,
            policyAmount,
            walletAmount,
            subsidyCouponAmount,
            chargeCouponAmount,
            enterpriseCodeAmount,
            creditLimitAmount,
            policyRefundAmount,
            walletRefundAmount,
            subsidyCouponRefundAmount,
            chargeCouponRefundAmount,
            enterpriseCodeRefundAmount,
            creditLimitRefundAmount
        }

        const paymentType = {
            1: '一卡通',
            2: '现金',
            5: '代金券',
            31: '微信',
            32: '支付宝'
        }
        response.info.details = keyMerge(
            details.filter(({ type }) => /^(1|2|3|4|5)$/.test(type.toString())),
            ['type', 'pid', 'amt', 'specs']
        ).map(({ data }) => {
            const meal = data[0]

            return {
                ...meal,
                quantity: data.length // 该数量只展示，并未乘入“amt”和“amt_dues”中。
            }
        })

        response.info.payments = payments.map((item) => {
            item.typename = paymentType[item.type] || ''

            return item
        })
        response.info.refunds = returns.map((item) => {
            item.typename = paymentType[item.type] || ''

            return item
        })

        return response.info
    })
}

const cancelDatum = ({ seq, state, type, msg = '' }) => {
    return perfect({
        url: 'v1/ordering/bill_cancel',
        data: {
            params: {
                seq,
                state,
                type,
                msg
            }
        }
    })
}

const getData = ({
    customerid,
    typeid,
    sdate = '',
    edate = '2100-1-1',
    part,
    stallid,
    tableid,
    state,
    evaluate_state,
    page = 1,
    rows = 10
}) => {
    return perfect({
        url: 'v1/order/dish_trade',
        data: {
            params: {
                customerid,
                typeid,
                sdate,
                edate,
                part,
                stallid,
                tableid,
                state,
                evaluate_state,
                page,
                rows
            }
        }
    }).then((response) => {
        const { list } = response
        const timestamp = moment().valueOf()

        response.list = list.map((item) => {
            // 撤单的订单会给type增加1000
            item.type_value = item.type % 1000
            item.prod = item.prod.filter(({ type }) =>
                /^(1|2|3|4|5)$/.test(type.toString())
            )
            item.properties = item.properties ? JSON.parse(item.properties) : {}
            item.can_cancel =
                moment(
                    item.properties.cancel_time,
                    'YYYY-MM-DD HH:mm:ss'
                ).valueOf() >= timestamp
            item.use_date = item.use_date
                .toString()
                .replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3')
            item.balance_name = item.surplus.toFixed(2)
            item.amt_name = item.amt.toFixed(2)

            return item
        })

        return response
    })
}

const getBox = ({ seq }) => {
    return perfect({
        url: 'v1/box/infoorder',
        data: {
            params: {
                seq
            }
        }
    }).then((response) => {
        const { booktime, porttime } = response.info

        response.info.booktime = `${booktime.split(' ')[0]} ${porttime}`

        return response
    })
}

const getBoxes = ({ customerid, page = 1, rows = 10, state }) => {
    return perfect({
        url: 'v1/box/order',
        data: {
            params: {
                customerid,
                page,
                rows,
                state
            }
        }
    }).then((response) => {
        response.list = response.list.map((item) => {
            const { booktime } = item

            item.booktime = booktime.split(' ')[0]

            return item
        })

        return response
    })
}

const getTakeTimes = ({ shop, date, part, stall, order_way, takemode }) => {
    return perfect({
        url: 'v1/ordering/shop_time',
        data: {
            params: {
                shop,
                date,
                part,
                stall,
                order_way,
                takemode
            }
        }
    })
}

const getSum = ({ stall, amt_dues, part, customer, type, prods, takemode }) => {
    return perfect({
        url: 'v1/policy/getactivepolicy',
        data: {
            params: {
                stall,
                amt_dues,
                part,
                customer,
                type,
                prods,
                takemode
            }
        },
        transformResponse: [
            (response) => {
                response = JSON.parse(response)

                const { code, msg, order, prods } = response.data || {}

                return {
                    code:
                        response.success && code === 1
                            ? 1
                            : response.errCode || code,
                    list: [],
                    total: 0,
                    info: {
                        ...order,
                        prods
                    },
                    msg: response.errMessage || msg
                }
            }
        ]
    })
}

const getReserves = ({ customerid, page, rows }) => {
    return perfect({
        url: 'v1/ordering/reportmeallist',
        data: {
            params: {
                page,
                rows,
                customerid
            }
        }
    })
}

const cancelBox = ({ seq }) => {
    return perfect({
        url: 'v1/box/cancelorder',
        data: {
            params: {
                seq
            }
        }
    })
}

const requestPayment = ({
    vaild,
    seq,
    tradePaymentType,
    pay_code,
    openid,
    memberid,
    customerid,
    couponnumber,
    couponamt,
    isuseenterprisepay,
    RedirectUri
}) => {
    const toMap = {
        wchat: 'wechat',
        wap: 'wechatMWAP'
    }

    return new Promise((resolve, reject) => {
        if (vaild) {
            perfect({
                url: 'v1/ordering/bill_pay',
                data: {
                    params: {
                        seq,
                        tradePaymentType:
                            tradePaymentType === 'wchat'
                                ? 'wap'
                                : tradePaymentType,
                        pay_code,
                        openid,
                        customerid,
                        couponnumber,
                        couponamt,
                        isuseenterprisepay,
                        memberid,
                        RedirectUri,
                        to: toMap[tradePaymentType]
                    }
                },
                transformResponse: [
                    (response) => {
                        response = JSON.parse(response)

                        const { code, msg, wechat } = response.data || {}

                        return {
                            code:
                                response.success && code === 1
                                    ? 1
                                    : response.errCode || code,
                            list: [],
                            total: 0,
                            info: wechat,
                            msg: response.errMessage || msg
                        }
                    }
                ]
            })
                .then((response) => {
                    if (tradePaymentType === 'customer_id') {
                        resolve()
                    } else if (
                        tradePaymentType === 'wchat' ||
                        tradePaymentType === 'wap'
                    ) {
                        response.info.passtrade_result = JSON.parse(
                            response.info.passtrade_result
                        )

                        resolve(response.info)
                    }
                })
                .catch((response) => reject(response))
        } else {
            perfect({
                url: 'v1/tray/pay',
                data: {
                    params: {
                        seq
                    }
                }
            })
                .then((response) => {
                    resolve(response.info)
                })
                .catch((response) => reject(response))
        }
    })
}

const getAddresses = ({ shopid, name = '', page = 1, rows = 10 } = {}) => {
    return perfect({
        url: 'v1/shop/shop_address_list',
        data: {
            params: {
                shopid,
                name,
                page,
                rows
            }
        }
    })
}

const getAllAddresses = ({ shopid }) => {
    return new Promise((resolve, reject) => {
        const rows = 1000
        const factory = (page, array, callback) => {
            getAddresses({
                shopid,
                page,
                rows
            })
                .then((response) => {
                    const { list, total } = response

                    array = array.concat(list)

                    if (total === array) {
                        factory(page + 1, array, callback)
                    } else {
                        callback && callback(array)
                    }
                })
                .catch((error) => reject(error))
        }

        factory(1, [], (response) => {
            for (let i = 0; i < response.length; i++) {
                const item = response[i]

                if (item.parent > 0) {
                    const index = response.findIndex(
                        ({ id }) => id === item.parent
                    )

                    if (index > -1) {
                        response[index].children = (
                            response[index].children || []
                        ).concat([item])
                    }
                }

                item.children = item.children || []
            }

            resolve({
                list: response.filter((item) => !item.parent)
            })
        })
    })
}

const getRefundReasons = () => {
    return perfect({
        url: 'v1/setting/config',
        data: {
            params: {
                no: 'booksetting'
            }
        }
    }).then((response) => {
        const data = JSON.parse(response.list[0].val)

        data.appeal = data.appeal.split(',')

        return data
    })
}

const refundDatum = ({
    merchant,
    type,
    orderno,
    amt,
    mob,
    reason = '',
    remark = '',
    images = [],
    member,
    membername,
    customerid,
    customername,
    shop,
    stall,
    detail = []
}) => {
    return perfect({
        url: 'v1/order/createdishtradeappeal',
        data: {
            params: {
                merchant,
                type,
                orderno,
                amt,
                mob,
                reason,
                remark,
                images: JSON.stringify(images),
                member,
                membername,
                customerid,
                customername,
                shop,
                stall,
                detail: JSON.stringify(detail)
            }
        }
    })
}

const getRefund = ({ id }) => {
    return perfect({
        url: 'v1/order/infodishtradeappeal',
        data: {
            params: {
                id
            }
        },
        mute: true
    })
}

const addComment = ({
    seq,
    star,
    content = '',
    prod_json = [],
    pic_json = []
}) => {
    const { phone = '' } = window.$store.state.user
    return perfect({
        url: 'v1/order/ordereval',
        data: {
            params: {
                seq,
                star,
                content,
                prod_json: JSON.stringify(prod_json),
                pic_json: JSON.stringify(pic_json),
                mob: phone
            }
        }
    })
}

const getComments = ({ page = 1, rows = 20 } = {}) => {
    const { phone = '' } = window.$store.state.user
    return perfect({
        url: 'v1/order/listeval',
        data: {
            params: {
                page,
                rows,
                mob: phone
            }
        }
    }).then((response) => {
        if (response.code === 1) {
            response.list = response.list.map((item) => {
                item.pic_json = JSON.parse(item.pic_json)
                item.prod_json = JSON.parse(item.prod_json)
                item.star = Number(item.star)

                return item
            })
        }

        return response
    })
}

const confirmDatum = ({ seq, shop, stall }) => {
    return perfect({
        url: 'v1/ordering/bill_action',
        data: {
            params: {
                type: 11,
                seq,
                shop,
                stall
            }
        }
    })
}

export default {
    addDatum,
    getDetail,
    cancelDatum,
    getData,
    getBox,
    getBoxes,
    getTakeTimes,
    getSum,
    getReserves,
    cancelBox,
    requestPayment,
    getAddresses,
    getAllAddresses,
    getRefundReasons,
    refundDatum,
    getRefund,
    addComment,
    getComments,
    confirmDatum
}
