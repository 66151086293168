<template>
    <div :class="`container ${mask ? 'mask' : ''}`">
        <div class="inner">{{ title }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            mask: false
        }
    },
    methods: {
        update(options) {
            this.title = options.title
            this.mask = options.mask
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    &.mask {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none;
    }
}
.inner {
    padding: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
}
</style>
