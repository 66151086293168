import moment from 'moment'
import login from './login'
import home from './home'
import member from './member'
import order from './order'
import canteen from './canteen'
import meal from './meal'
import others from './others'

const screw = {
    async filterCart(data) {
        // 过滤过期时间
        data = data.filter(
            (item) =>
                moment(item.date).valueOf() >=
                moment(moment().format('YYYY-MM-DD')).valueOf()
        )

        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            const { canteenID, gatewayID, periodID, date, count, detail } = item
            const c = await canteen.getCanteen({ id: canteenID })
            const gateway = await canteen.getGateway({ id: gatewayID })
            const period = await canteen.getPeriod({ id: periodID })

            item.canteenName = c.name
            item.gatewayName = gateway.name
            item.periodName = period.name

            if (detail.policy_type === 21) {
                const { meal_list: setMeals, prod_list: meals } =
                    await meal.getData({
                        stall: gatewayID,
                        date,
                        times: periodID
                    })

                switch (detail.type) {
                    case 2:
                        item.detail =
                            meals.find(({ id }) => id === detail.id) || null
                        break
                    case 3:
                        item.detail =
                            setMeals.find(({ id }) => id === detail.id) || null
                        break
                    default:
                        item.detail = null
                        break
                }
            } else if (detail.policy_type === 3) {
                const setMeals = await meal
                    .getSetMeals({
                        stall: gatewayID,
                        date,
                        times: periodID
                    })
                    .then((response) => response.list)

                item.detail =
                    setMeals.find(({ id }) => id === detail.id) || null
            } else {
                item.detail = null
            }

            if (!item.detail || item.detail.stock === 0) {
                data.splice(i, 1)
                i--
            } else {
                item.count =
                    item.detail.stock === ''
                        ? count
                        : count > item.detail.stock
                        ? item.detail.stock
                        : count
            }
        }

        return data
    }
}

export default {
    login,
    home,
    member,
    order,
    canteen,
    meal,
    screw,
    others
}
