<template>
    <div :class="$style.curtain" v-if="value" @click="closeHandler">
        <div class="container" @click.stop="">
            <div class="box">
                <div class="main">
                    <div class="title">{{ title }}</div>
                    <div class="content">
                        <slot></slot>
                    </div>
                </div>
                <div class="footer">
                    <div class="button button--cancel" @click="cancelHandler">
                        取消
                    </div>
                    <div
                        class="button button--confirm"
                        @click="$emit('confirm')"
                    >
                        确定
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        cancelHandler(e) {
            this.$emit('input', false)
            this.$emit('cancel')
        },
        confirmHandler(e) {
            this.triggerEvent('confirm', e)
        },
        closeHandler() {
            this.$emit('input', false)
            this.$emit('close')
        }
    }
}
</script>

<style module>
.curtain {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
</style>

<style lang="less" scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 30px;
}
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    background: #fff;
    border-radius: 24px;
}
.main {
    width: 100%;
    padding: 64px 48px;
}
.title {
    font-weight: bold;
    text-align: center;
}
.content {
    width: 100%;
    margin-top: 32px;
}
.footer {
    display: flex;
    width: 100%;
    font-weight: bold;
    border-top: 1px solid #f2f2f2;
}
.button {
    flex-grow: 1;
    width: 0;
    height: 96px;
    line-height: 96px;
    text-align: center;
}
.button--cancel {
    color: #1a1a1a;
}
.button--confirm {
    color: #364897;
    border-left: 1px solid #f2f2f2;
}
</style>
