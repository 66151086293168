import LoadingComponent from './index.vue'
const $loading = {
    install: (Vue) => {
        const Loading = Vue.extend(LoadingComponent);
        const vm = new Loading();
        const templateEle = vm.$mount().$el;
        document.body.appendChild(templateEle);
        Vue.prototype.$showLoading = () => {
            vm.show = true
        }
        Vue.prototype.$hideLoading = () => {
            vm.show = false
        }
    }
}
export default $loading;