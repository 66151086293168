import axios from 'axios'

const request = axios.create({
    method: 'POST',
    // baseURL: 'https://lianxing.sovellzp.com' + '/atlantis/api/devops/clientapi/',
    baseURL: window.location.origin + '/atlantis/api/devops/clientapi/',
    headers: { 'content-type': 'application/json' },
    useToken: true,
    useErrorTip: true,
    timeout: 5000
})

request.interceptors.request.use((config) => {
    if (config.useToken) {
        config.headers['authorization'] = `Bearer ${JSON.parse(
            window.localStorage.getItem('PalmPilotH5Token')
        )}`
    }

    return config
})

request.interceptors.response.use(
    (response) => {
        const { config, data } = response

        if (data.success && data.rstCode === 1) {
            return data.data
        } else {
            if (config.useErrorTip) {
                console.log(data.errMessage)
            }

            return Promise.reject(data)
        }
    },
    (error) => {
        const { config, response } = error

        if (config.useErrorTip) {
            console.log(response.data.errMessage)
        }

        if (response.data.errCode === 'token_incorrect_format') {
            window.localStorage.removeItem('PalmPilotH5Token')
        }

        return Promise.reject(response.data)
    }
)

export default request
