import container from './index.vue'

export default {
    install: (Vue) => {
        const Toast = Vue.extend(container)

        Vue.prototype.$showToast = (options) => {
            const vm = new Toast()
            const dom = vm.$mount().$el

            vm.update(options)
            document.body.appendChild(dom)

            const timer = setTimeout(
                () => {
                    clearTimeout(timer)
                    document.body.removeChild(dom)
                },
                options.duration === undefined ? 1500 : options.duration
            )
        }
    }
}
