export default [
    {
        path: '/feature',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                path: 'reserve-meal',
                meta: { showNavigation: false },
                component: () => import('@/page/feature/reserve-meal/index')
            },
            {
                path: 'reserve',
                meta: { showNavigation: false },
                component: () => import('@/page/feature/reserve/index')
            },
            {
                path: 'reserve/result',
                meta: { title: '报餐' },
                component: () => import('@/page/feature/reserve/result')
            },
            {
                path: 'reserve-set-meal',
                meta: { showNavigation: false },
                component: () => import('@/page/feature/reserve-set-meal/index')
            },
            {
                path: 'box',
                meta: { showNavigation: false },
                component: () => import('@/page/feature/box/index')
            },
            {
                path: 'box/result',
                meta: { title: '预订结果' },
                component: () => import('@/page/feature/box/result')
            },
            {
                path: 'canteen',
                meta: { title: '餐厅实况' },
                component: () => import('@/page/feature/canteen')
            },
            {
                path: 'scan',
                meta: { title: '扫码点餐' },
                component: () => import('@/page/feature/scan/index')
            },
            {
                path: 'scan/detail',
                meta: { title: '订单详情' },
                component: () => import('@/page/feature/scan/detail')
            },
            {
                path: 'collect',
                meta: { title: '扫码付款' },
                component: () => import('@/page/feature/scan/collect-money')
            }
        ]
    }
]
