import _ from 'lodash'
import navi from './navi/index'
import request from '@/library/request/controller'
const storagePrefix = 'PalmPilotH5'

export default {
    state: {
        type: '',
        token: '',
        user: null,
        bindUser: null,
        diner: null,
        feature: null,
        canteen: null,
        gateway: null,
        cart: null,
        address: null,
        currentTitle: '',
        chargeTradeData: null,

        targetWeight: 0, // 目标体重
        targetBMI: 0 // 目标bmi
    },
    getters: {},
    actions: {
        async bindUserPhone({ state, commit }, { payload }) {
            const data = await request({
                url: 'v3/user/bind/phone',
                data: payload.reqData,
                method: 'PUT'
            })
            return {
                data: data
            }
        },
        async bindUserOpenId({ state, commit }, { payload }) {
            const data = await request({
                url: 'v3/user/bind/openid',
                data: payload.reqData
            })
            return {
                data: data
            }
        },
        async wxOauthLogin({ state, commit }, { payload }) {
            const data = await request({
                url: 'user/login',
                data: payload.reqData,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            })
            return {
                data: data
            }
        },
        async pay({ state, commit }, { payload }) {
            const appId = localStorage.getItem('PalmPilotH5WechatAppId') //'wx5735430e971f40ec'//
            let reqData = {
                ...payload.reqData,
                appId: appId
            }
            const ua = navigator.userAgent.toLowerCase()

            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                if (ua.match(/wxwork/i) == 'wxwork') {
                    //("微信企业端")
                    reqData.payEnvironment = 'wechatMWAP'
                } else {
                    //("微信")
                    const openId = await request({
                        url: 'v3/user/check/auth',
                        data: {
                            appId: appId
                        }
                    })
                    if (!openId) {
                        //const redirect_uri = location.origin+"/restaurant/h5/applet/wechat/bindUser"
                        const redirect_uri =
                            location.origin +
                            '/restaurant/h5/applet/wechat/bindUserpay'
                        window.location.replace(
                            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
                                redirect_uri
                            )}&response_type=code&scope=snsapi_base&state=1232#wechat_redirect`
                        )
                        return false
                    }
                    reqData.payEnvironment = 'wechat'
                }
            }

            if (ua.match(/AlipayClient/i) == 'alipayclient') {
                //("支付宝")
                reqData.payEnvironment = 'alipay'
            }

            if (ua.indexOf('dingtalk') >= 0) {
                //("钉钉")
                reqData.payEnvironment = 'alipay'
            }
            if (!reqData.payEnvironment) {
                //alert('无支付类型')
                commit('saveChargeTradeData', {
                    payload: reqData
                })
                payload.that.$router.replace({
                    path: '/page/navi/charge/selectchannel'
                })
                return false
            }
            const data = await request({
                url: payload.reqUrl,
                data: reqData
            })

            if (data && data.extendParams) {
                if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                    if (ua.match(/wxwork/i) == 'wxwork') {
                        location.replace(
                            data.vegaResult.thirdPartyData.h5_url +
                                `&redirect_url=${encodeURIComponent(
                                    payload.reqData.redirectUri +
                                        '?seq=' +
                                        data.id
                                )}`
                        )
                    } else {
                        location.replace(data.extendParams.notifyUri)
                    }
                } else if (ua.match(/AlipayClient/i) == 'alipayclient') {
                } else if (ua.indexOf('dingtalk') >= 0) {
                    //("钉钉")
                } else {
                    location.replace(data.extendParams.notifyUri)
                }
            }
        }
    },
    mutations: {
        saveChargeTradeData(state, { payload }) {
            state.chargeTradeData = payload
        },
        updateSessionStorage(state, data) {
            const isString = _.isString(data)
            const key = isString ? data : data.key
            const storageKey = `${storagePrefix}${key.replace(
                /^[a-z_$]/,
                (value) => value.toUpperCase()
            )}`

            if (isString) {
                state[key] = window.sessionStorage.getItem(storageKey)
                    ? JSON.parse(window.sessionStorage.getItem(storageKey))
                    : ''
            } else {
                // 防止对undefined值进行存储，导致JSON.parse解析失败。
                const value = data.value === undefined ? null : data.value

                window.sessionStorage.setItem(storageKey, JSON.stringify(value))
                state[key] = value
            }
        },
        updateLocalStorage(state, data) {
            const isString = _.isString(data)
            const key = isString ? data : data.key
            const storageKey = `${storagePrefix}${key.replace(
                /^[a-z_$]/,
                (value) => value.toUpperCase()
            )}`

            if (isString) {
                state[key] = window.localStorage.getItem(storageKey)
                    ? JSON.parse(window.localStorage.getItem(storageKey))
                    : ''
            } else {
                const value = data.value === undefined ? null : data.value

                window.localStorage.setItem(storageKey, JSON.stringify(value))
                state[key] = value
            }
        },
        deleteSessionStorage(state, key) {
            const storageKey = `${storagePrefix}${key.replace(
                /^[a-z_$]/,
                (value) => value.toUpperCase()
            )}`

            window.sessionStorage.removeItem(storageKey)

            state[key] = null
        },
        deleteLocalStorage(state, key) {
            const storageKey = `${storagePrefix}${key.replace(
                /^[a-z_$]/,
                (value) => value.toUpperCase()
            )}`

            window.localStorage.removeItem(storageKey)

            state[key] = null
        },
        clearSessionStorage(state) {
            window.sessionStorage.clear()
            state.token = ''
        },
        clearLocalStorage(state) {
            window.localStorage.clear()
            state.token = ''
        },
        setTargetWtAdbi(state, o) {
            state.targetWeight = o?.targetWeight || 0
            state.targetBMI = o?.targetBMI || 0
        }
    },
    modules: {
        navi
    }
}
