export default [
    {
        path: '/order',
        component: {
            template: '<keep-alive><router-view /></keep-alive>'
            // template: '<router-view />'
        },
        children: [
            {
                path: '',
                meta: {
                    title: '订单中心',
                    showBackButton: false,
                    showTab: true
                },
                component: () => import('@/page/order/index/index')
            },
            {
                path: 'detail/meal',
                meta: {
                    title: '订单详情',
                    navigationStyle: `color: #fff; background: linear-gradient(90deg, #FF7500 0%, #FFA200 100%)`
                },
                component: () => import('@/page/order/detail/meal/index')
            },
            {
                path: 'detail/box',
                meta: {
                    title: '订单详情',
                    navigationStyle: `color: #fff; background: linear-gradient(90deg, #FF7500 0%, #FFA200 100%)`
                },
                component: () => import('@/page/order/detail/box/index')
            },
            {
                path: 'add',
                meta: {
                    title: '确认订单'
                },
                component: () => import('@/page/order/add/index/index')
            },
            {
                path: 'add/scan',
                meta: {
                    title: '确认订单'
                },
                component: () => import('@/page/order/add/scan')
            },
            {
                path: 'payment',
                meta: {
                    title: '支付'
                },
                component: () => import('@/page/order/payment/index')
            },
            {
                path: 'payment/result',
                meta: {
                    title: '支付结果'
                },
                component: () => import('@/page/order/payment/result')
            },
            {
                path: 'payment/callback',
                meta: {
                    title: '支付'
                },
                component: () => import('@/page/order/payment/callback')
            },
            {
                path: 'remark',
                meta: {
                    title: '订单备注'
                },
                component: () => import('@/page/order/remark')
            },
            {
                path: 'address',
                meta: {
                    title: '取餐地址'
                },
                component: () => import('@/page/order/address')
            },
            {
                path: 'refund',
                meta: {
                    title: '退款详情'
                },
                component: () => import('@/page/order/refund/index')
            },
            {
                path: 'refund/detail',
                meta: {
                    title: '退款详情'
                },
                component: () => import('@/page/order/refund/detail')
            },
            {
                path: 'comment',
                meta: {
                    title: '订单评价'
                },
                component: () => import('@/page/order/comment')
            }
        ]
    }
]
