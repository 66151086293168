import axios from 'axios'
import _ from 'lodash'

const accesses = {}

/*
    无论成功失败，保持返回的数据格式始终为：
    {
        code: null,
        list: [],
        total: 0,
        info: null,
        msg: ''
    }
*/
const transformResponse = [
    (response) => {
        response = response ? JSON.parse(response) : {}

        const standard = {
            code: response.success ? 1 : response.errCode,
            list: [],
            total: 0,
            info: null,
            msg: response.errMessage
        }

        if (_.isArray(response.data)) {
            standard.list = response.data || []
            standard.total = Object.hasOwnProperty.call(response, 'total')
                ? response.total
                : 0
        } else if (Object.hasOwnProperty.call(response.data || {}, 'code')) {
            const { code, list, info, msg, total } = response.data

            standard.code = code
            standard.list = list || []
            standard.total = total
            standard.info = info
            standard.msg = msg
        } else {
            standard.info = response.data
        }

        return standard
    }
]

const request = (config) => {
    const controller = new AbortController()

    config.signal = controller.signal
    config.identity = `${config.baseURL}${config.url}`

    if (!config.repeat && accesses[config.identity]) {
        controller.abort()
    } else {
        accesses[config.identity] = true
    }

    config.data = config.data || {}

    return config
}

const success = (correctness) => {
    const { config, data } = correctness

    delete accesses[config.identity]

    return data.code === 1 ? data : Promise.reject(correctness)
}

const fail = (error) => {
    const { code, config } = error

    // 如果重复请求禁止，就不进行标识删除。
    if (code !== 'ERR_CANCELED') {
        delete accesses[config.identity]
    }

    return Promise.reject(error)
}

const instance = axios.create({
    baseURL: window.location.origin + '/atlantis/api/devops/clientapi/',
    headers: { 'content-type': 'application/json' },
    method: 'POST',
    timeout: 5000,
    repeat: false,
    useToken: true,
    useErrorTip: true,
    transformResponse
})

// 防重拦截
instance.interceptors.request.use(request)
instance.interceptors.response.use(success, fail)

export default instance
