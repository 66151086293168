import perfect from '@/library/request/perfect'
import Big from 'big.js'
import sha1 from 'crypto-js/sha1'
import CryptoJS from 'crypto-js'

const loginFromPhone = ({ phone, code }) => {
    return perfect({
        url: 'user/login',
        data: {
            phone,
            code,
            type: 'user_sms_login'
        },
        useToken: false,
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => response.info)
}

const loginFeishuPhone = ({ appId, phone }) => {
    const code = CryptoJS.MD5(`phone=${phone}&key=2323dsfadfewrasa3434`)
        .toString()
        .toUpperCase()
    return perfect({
        url: 'user/login',
        data: {
            type: 'user_sign_login',
            appId,
            phone: phone,
            code
        },
        useToken: false,
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => response.info)
}

const loginFromName = ({ username, password, appId }) => {
    return perfect({
        url: 'user/login',
        data: {
            type: 'user_password_login',
            appType: 1,
            appId,
            username,
            password
        },
        useToken: false,
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    }).then((response) => response.info)
}

const getMessageCode = ({ type, phone }) => {
    return perfect({
        url: 'v2/sms/send/code',
        data: {
            phone,
            type
        },
        useToken: false
    })
}

const exit = () => {
    return perfect({
        url: `v2/logout`
    })
}

const getBindUsers = () => {
    return perfect({
        url: `v3/customer/customer/query/page`
    }).then((response) => {
        response.list
            .filter(({ status }) => status === 1)
            .map((item) => {
                item.sumBalance = Number(
                    new Big(item.balance)
                        .plus(item.subsidyCouponBalance)
                        .plus(item.chargeCouponBalance)
                )
                item.sumBalanceName = item.sumBalance.toFixed(2)
                item.creditBalance = Number(
                    new Big(item.creditLimit).minus(item.usedCreditLimit)
                )
                item.subsidyCouponBalanceName =
                    item.subsidyCouponBalance.toFixed(2)
                item.balanceName = item.balance.toFixed(2)
                item.chargeCouponBalanceName =
                    item.chargeCouponBalance.toFixed(2)
                item.enterpriseName = item.enterpriseName || ''
                item.departmentPath = item.departmentPath || ''
                item.jobTitleName = item.jobTitleName || ''
                item.uniqueIdentify = item.uniqueIdentify || ''
                item.customerTypeName = item.customerTypeName || ''

                return item
            })

        return response.list
    })
}

const getVouchers = async ({ customerId }) => {
    return perfect({
        url: 'v3/customer/coupon/list',
        data: {
            customerId
        }
    }).then((response) => {
        return response.list.map((item) => {
            item.effectiveTime = Number(item.effectiveTime)
            item.expireTime = Number(item.expireTime)

            return item
        })
    })
}

const getBindUser = ({ customerId } = {}) => {
    return perfect({
        url: 'v3/customer/body/get',
        data: {
            customerId
        }
    }).then((response) => {
        const { height, weight, intensity } = response.info
        const intensities = {
            1: '轻体力劳动',
            2: '中体力劳动',
            3: '重体力劳动'
        }

        response.info.intensity = intensity ? Number(intensity) : null
        response.info.intensityName = intensity ? intensities[intensity] : ''
        response.info.bmi =
            height && weight
                ? Number(
                      new Big(weight)
                          .div(new Big(height).div(100).pow(2))
                          .toFixed(1)
                  )
                : null

        return response.info
    })
}

const setBindUser = ({ id, name, customerGender, birthdate }) => {
    return perfect({
        url: 'v3/customer/',
        data: {
            id,
            name,
            customerGender,
            birthdate
        },
        method: 'PUT'
    })
}

const setBindUserDetail = ({
    id,
    customerId,
    weight,
    height,
    anaphylactogen,
    special,
    intensity,
    targetWeight,
    favoriteCate,
    hateCate,
    favoritePeppery,
    quotaMode,
    aboveQuota,
    targetBmi
}) => {
    return perfect({
        url: 'v3/customer/body',
        data: {
            id,
            customerId,
            weight,
            height,
            anaphylactogen,
            special,
            intensity,
            targetWeight,
            favoriteCate,
            hateCate,
            favoritePeppery,
            quotaMode,
            aboveQuota,
            targetBmi
        },
        method: 'PUT'
    })
}

const setUser = ({ id, picture }) => {
    return perfect({
        url: `v3/user`,
        data: {
            id,
            picture
        },
        method: 'PUT'
    })
}

const getUserOpenID = ({ appId, phone }) => {
    return perfect({
        url: `v3/user/get/bind/openid`,
        data: {
            appId,
            phone
        }
    }).then((response) => response.info)
}

const bindUserOpenID = ({ code, appId }) => {
    return perfect({
        url: 'v3/user/bind/openid',
        data: {
            code,
            appId
        }
    })
}

const getUserData = ({ customerId }) => {
    return perfect({
        url: 'v3/customer/body/get',
        data: {
            customerId
        }
    }).then((response) => {
        const { height, weight, intensity } = response.info
        const intensities = {
            1: '轻体力劳动',
            2: '中体力劳动',
            3: '重体力劳动'
        }

        response.info.intensity = intensity ? Number(intensity) : null
        response.info.intensityName = intensity ? intensities[intensity] : ''
        response.info.bmi =
            height && weight
                ? Number(
                      new Big(weight)
                          .div(new Big(height).div(100).pow(2))
                          .toFixed(1)
                  )
                : null

        return response.info
    })
}

const setUserData = ({
    id,
    customerId,
    weight,
    height,
    anaphylactogen,
    special,
    intensity,
    targetWeight,
    favoriteCate,
    hateCate,
    favoritePeppery,
    quotaMode,
    aboveQuota,
    targetBmi
}) => {
    return perfect({
        url: 'v3/customer/body',
        data: {
            id,
            customerId,
            weight,
            height,
            anaphylactogen,
            special,
            intensity,
            targetWeight,
            favoriteCate,
            hateCate,
            favoritePeppery,
            quotaMode,
            aboveQuota,
            targetBmi
        },
        method: 'PUT'
    })
}

const getWechatJsApiConfigSign = ({ appID, url }) => {
    const getRandomString = () => Math.random().toString(36).substring(2)
    const getTimestamp = () => parseInt(new Date().getTime() / 1000) + ''

    const format = function (args) {
        let keys = Object.keys(args)
        keys = keys.sort()
        let newArgs = {}
        keys.forEach(function (key) {
            newArgs[key.toLowerCase()] = args[key]
        })

        let string = ''
        for (let k in newArgs) {
            string += '&' + k + '=' + newArgs[k]
        }

        string = string.substring(1)

        return string
    }

    return perfect({
        url: 'v3/trade/jsapi/ticket',
        params: {
            appid: appID
        },
        method: 'GET'
    }).then((response) => {
        const jsapi_ticket = response.info
        const data = {
            jsapi_ticket,
            nonceStr: getRandomString(),
            timestamp: getTimestamp(),
            url
        }

        data.signature = sha1(format(data)).toString()

        return data
    })
}

export default {
    loginFromPhone,
    loginFeishuPhone,
    loginFromName,
    getMessageCode,
    exit,
    getBindUsers,
    getVouchers,
    getBindUser,
    setBindUser,
    setBindUserDetail,
    setUser,
    getUserOpenID,
    bindUserOpenID,
    getUserData,
    setUserData,
    getWechatJsApiConfigSign
}
