<template>
    <div
        class="app-curtain"
        :style="`z-index: ${zIndex}; background: ${background};`"
        v-if="value"
        @click.self="$emit('input', false)"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        zIndex: {
            type: Number,
            default: 1000
        },
        background: {
            type: String,
            default: 'rgba(0, 0, 0, 0.5)'
        }
    }
}
</script>

<style lang="less" scoped>
.app-curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
