<template>
    <div class="mask" v-show="show">
        <div class="loading"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mask {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading {
        margin: 10% auto;
        /*background-color: #8c939d;*/
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 4px rgba(0, 0, 0, 0.25) solid;
        border-top: 4px black solid;
        animation: rotate 1000ms infinite;
    }
}
</style>
