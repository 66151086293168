<template>
    <div :class="$style.container">
        <img class="image" src="@/image/empty.png" />
        <div class="tip">{{ message }}</div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: '暂无数据'
        }
    }
}
</script>

<style module>
.container {
    width: 100%;
    height: 100%;
    padding: 100px 32px;
}
</style>

<style lang="less" scoped>
.image {
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
}

.tip {
    color: #1a1a1a;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}
</style>
