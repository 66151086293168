import container from './index.vue'

export default {
    install: (Vue) => {
        const Modal = Vue.extend(container)

        Vue.prototype.$showModal = (options) => {
            const vm = new Modal()
            const dom = vm.$mount().$el

            options.complete = vm.update({
                ...options,
                complete(e) {
                    document.body.removeChild(dom)

                    if (options.complete) {
                        options.complete(e)
                    }
                }
            })
            document.body.appendChild(dom)
        }
    }
}
