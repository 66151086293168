import login from './login'
import home from './home'
import member from './member'
import location from './location'
import order from './order'
import feature from './feature'
import navi from './navi'
import nutrition from './nutrition'

export default [
    ...login,
    ...home,
    ...member,
    ...location,
    ...order,
    ...feature,
    ...navi,
    ...nutrition,
    {
        path: '/wechat/bindUser:type',
        component: () => import('@/page/oauth')
    },
    {
        path: '/feishu/auth',
        meta: { showNavigation: false },
        component: () => import('@/page/oauth/feishu')
    },
    {
        path: '*',
        component: () => import('@/page/home/index')
    }
]
