export default [
    {
        path: '/member',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                path: '',
                meta: { title: '我的', showNavigation: false, showTab: true },
                component: () => import('@/page/member/index')
            },
            {
                path: 'setting',
                meta: { title: '设置' },
                component: () => import('@/page/member/setting/index')
            },
            {
                path: 'phone',
                meta: { title: '修改手机号' },
                component: () => import('@/page/member/phone')
            },
            {
                path: 'qrcode',
                meta: { showNavigation: false },
                component: () => import('@/page/member/qrcode')
            },
            {
                path: 'data',
                meta: { title: '修改资料' },
                component: () => import('@/page/member/data/index')
            },
            {
                path: 'weight',
                meta: { title: '体重' },
                component: () => import('@/page/member/weight')
            },
            {
                path: 'bmi',
                meta: { title: 'BMI指数' },
                component: () => import('@/page/member/bmi')
            },
            {
                path: 'comment',
                meta: { title: '我的评价' },
                component: () => import('@/page/member/comment')
            }
        ]
    }
]
