export default [
    {
        path: '/login',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                path: '',
                meta: { showNavigation: false },
                component: () => import('@/page/login/index')
            },
            {
                path: 'phone',
                meta: { showNavigation: false },
                component: () => import('@/page/login/phone')
            },
            {
                path: 'account',
                meta: { showNavigation: false },
                component: () => import('@/page/login/account')
            }
        ]
    }
]
