export default [
    {
        path: '/home',
        component: {
            template: '<router-view />'
        },
        children: [
            {
                path: '',
                meta: { showNavigation: false },
                component: () => import('@/page/home/index')
            },
            {
                path: 'palm-pilot',
                meta: { showNavigation: false, showTab: true },
                component: () => import('@/page/home/palm-pilot')
            },
            {
                path: 'praise',
                meta: { showNavigation: false, showTab: true },
                component: () => import('@/page/home/praise')
            },
            {
                path: 'school',
                meta: { showNavigation: false, showTab: true },
                component: () => import('@/page/home/school')
            }
        ]
    },
    {
        path: '/bannerDetail',
        meta: {
            title: '详情',
            showNavigation: true,
            showTab: false
        },
        component: () => import('@/page/home/component/banner-detail')
    }
]
