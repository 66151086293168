const updateHtmlSize = () => {
    let documentWidth =
        document.documentElement.clientWidth || document.body.clientWidth
    document.documentElement.style.fontSize = documentWidth / 20 + 'px'
}

const updateHtmlSize1 = (designWidth = 750, ratio = 1) => {
    let documentWidth = document.documentElement.clientWidth

    if (documentWidth > designWidth) {
        documentWidth = designWidth
    }

    /**
     * 1px与1rem的转换
     * fontSize = documentWidth * 1px / (designWidth * 1rem * ratio)
     * fontSize = documentWidth / (designWidth * ratio)
     */
    document.documentElement.style.fontSize =
        documentWidth / (designWidth * ratio) + 'px'
}

const keyMerge = (array, keys = []) => {
    const index = {}
    const data = []

    for (let i = 0; i < array.length; i++) {
        const item = array[i]
        const map = {}

        for (let i = 0; i < keys.length; i++) {
            map[keys[i]] = keys[i].split('.').reduce((start, value) => {
                return start[value]
            }, item)
        }

        const key = Object.values(map).join('-')

        if (index[key] === undefined) {
            data.push({
                map,
                data: [item]
            })
            index[key] = data.length - 1
        } else {
            data[index[key]].data.push(item)
        }
    }

    return data
}

const getBrowserEnvironmentName = () => {
    const sign = window.navigator.userAgent.toLowerCase()
    console.log('=> getBrowserEnvironmentName <=', sign)

    if (/micromessenger/.test(sign)) {
        if (/wxwork/.test(sign)) {
            return 'workWechat'
        } else {
            return 'wechat'
        }
    } else if (sign.match(/Alipay/i) == 'alipay') {
        return 'alipay'
    } else if (sign.match(/Feishu/i) == 'feishu') {
        return 'feishu'
    } else {
        return sign
    }
}

export { updateHtmlSize, keyMerge, getBrowserEnvironmentName }
