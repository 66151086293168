<template>
    <div :class="$style.container">
        <div
            class="iconfont icon-left back-button"
            v-if="showBackButton"
            @click="$router.back()"
        ></div>
        <div>{{ title }}</div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        showBackButton: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style module>
.container {
    position: relative;
    width: 100%;
    height: 88px;
    padding: 0 70px;
    font-weight: bold;
    line-height: 88px;
    text-align: center;
    background: #fff;
}
</style>

<style lang="less" scoped>
.back-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 88px;
    font-size: 30px;
}
</style>
