<template>
    <button
        :class="`
            ${$style.container} 
            ${$style['type--' + type]} 
            ${$style['size--' + size]} 
            ${plain ? $style.plain : ''}
            ${disabled ? $style.disabled : ''}`"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'medium'
        },
        type: {
            type: String,
            default: 'default'
        },
        plain: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style module>
.container {
    display: block;
    color: #ccc;
    font-size: 0.74rem;
    text-align: center;
    line-height: 1.5;
    border-radius: 50px;
    border: 1px solid #ccc;
}
.container:active {
    opacity: 0.7;
}

.type--primary {
    color: #fff;
    background: #ff7500;
    border-color: #ff7500;
}
.type--primary.plain {
    color: #ff7500;
    background: #fff;
}

.type--info {
    color: #fff;
    background: #ccc;
    border-color: #ccc;
}
.type--default {
    color: #666;
    background: #fff;
    border-color: #ccc;
}
.size--small {
    padding: 12px 32px;
}

.size--medium {
    padding: 20px 32px;
}

.size--large {
    padding: 24px 32px;
    font-size: 0.8rem;
    border-radius: 60px;
}

.disabled {
    color: #fff;
    background: #ccc;
    border: 1px solid #ccc;
}
.disabled:active {
    opacity: 1;
}
</style>
