<template>
    <div class="container">
        <div class="inner">
            <div class="main">
                <div class="title">{{ title }}</div>
                <div class="content" v-html="content"></div>
            </div>
            <div class="footer">
                <div
                    class="button button--cancel"
                    v-show="showCancel"
                    @click="cancel"
                >
                    {{ cancelText }}
                </div>
                <div class="button button--confirm" @click="confirm">
                    {{ confirmText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            content: '',
            showCancel: true,
            cancelText: '取消',
            confirmText: '确定',
            success: undefined,
            complete: undefined
        }
    },
    methods: {
        update({
            title = '',
            content = '',
            showCancel = true,
            cancelText = '取消',
            confirmText = '确定',
            success,
            complete
        }) {
            this.title = title
            this.content = content
            this.showCancel = showCancel
            this.cancelText = cancelText
            this.confirmText = confirmText
            this.success = success
            this.complete = complete
        },
        cancel() {
            if (this.success) {
                this.success({
                    cancel: true,
                    confirm: false
                })
            }

            if (this.complete) {
                this.complete({
                    cancel: true,
                    confirm: false
                })
            }
        },
        confirm() {
            if (this.success) {
                this.success({
                    cancel: false,
                    confirm: true
                })
            }

            if (this.complete) {
                this.complete({
                    cancel: false,
                    confirm: true
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    font-size: 30px;
    background: #fff;
    border-radius: 24px;
}
.main {
    width: 100%;
    padding: 64px 48px;
}
.title {
    font-weight: bold;
    text-align: center;
}
.content {
    width: 100%;
    margin-top: 32px;
    text-align: center;
}
.footer {
    display: flex;
    width: 100%;
    font-weight: bold;
    border-top: 1px solid #f2f2f2;
}
.button {
    flex-grow: 1;
    width: 0;
    height: 96px;
    line-height: 96px;
    text-align: center;
}
.button--cancel {
    color: #1a1a1a;
}
.button--confirm {
    color: #364897;
    border-left: 1px solid #f2f2f2;
}
</style>
