import moment from 'moment'
import perfect from '@/library/request/perfect'

const getCanteen = ({ id }) =>
    perfect({
        url: `v1/shop/info`,
        data: {
            params: {
                id
            }
        }
    }).then((response) => response.info)

const getGateway = ({ id }) =>
    perfect({
        url: `v1/stall/info`,
        data: {
            params: {
                id
            }
        }
    }).then((response) => {
        const { pic } = response.info

        response.info.pic = /\.(jpg|jpeg|png)$/.test(pic) ? pic : ''

        return response.info
    })

const getPeriod = ({ id }) =>
    perfect({
        url: 'v1/shop/info_hours',
        data: {
            params: {
                id
            }
        }
    }).then((response) => response.info)

const getPeriods = ({
    shopid,
    stallid,
    state,
    page = 1,
    rows = 8 // 餐别只有8个
}) =>
    perfect({
        url: `v1/shop/list_hours`,
        data: {
            params: {
                shopid,
                stallid,
                state,
                page,
                rows
            }
        }
    }).then((response) => {
        return response.list
            .sort((a, b) => {
                if (a.stime && b.stime) {
                    return (
                        moment(a.stime, 'HH:mm').valueOf() -
                        moment(b.stime, 'HH:mm').valueOf()
                    )
                } else if (a.stime) {
                    return -1
                } else if (b.stime) {
                    return 1
                } else {
                    return 0
                }
            })
            .map((item) => {
                const { id, autoid, state } = item

                item.id = autoid
                item.autoid = id
                item.stime = item.stime === '24:00' ? '23:59' : item.stime
                item.etime = item.etime === '24:00' ? '23:59' : item.etime

                item.state_string =
                    state === 1 ? '已启用' : state === 0 ? '已禁用' : ''

                return item
            })
    })

const getDatum = (phone) => {
    return perfect({
        url: 'v2/sms/send/code',
        data: {
            phone,
            type: 'user_login'
        }
    })
}

const addReport = ({
    member,
    customer,
    merchant,
    usedate,
    times,
    shop,
    stall,
    remark = ''
}) => {
    return perfect({
        url: 'v1/ordering/reportmeal',
        data: {
            params: {
                member,
                customer,
                merchant,
                usedate,
                times,
                shop,
                stall,
                remark
            }
        }
    })
}

const getGatewayReservation = ({ stallid, order_way }) => {
    return perfect({
        url: 'v1/stall/orderset_info',
        data: {
            params: { stallid, order_way }
        }
    }).then(async (response) => {
        const info = response.info
        const { shop_hours_ordering } = info
        const currentClockDuration = moment
            .duration(moment().format('HH:mm:ss'))
            .asSeconds()
        const periods = await perfect({
            url: `v1/shop/list_hours`,
            data: {
                params: {
                    stallid: info.stallid,
                    state: 1,
                    page: 1,
                    rows: 8
                }
            }
        }).then((response) => response.list)

        info.shop_hours_ordering = shop_hours_ordering
            ? JSON.parse(shop_hours_ordering)
            : { time: [] }
        info.periods = []

        for (let i = 0; i < info.shop_hours_ordering.time.length; i++) {
            const item = info.shop_hours_ordering.time[i]
            const detail =
                periods.find(({ autoid }) => autoid === item.id) || {}
            const { book_sday, book_stime, book_eday, book_etime } = item
            const startClockDuration = moment.duration(book_stime).asSeconds()
            const endClockDuration = moment.duration(book_etime).asSeconds()
            const range_min_days =
                currentClockDuration >= endClockDuration
                    ? book_eday + 1
                    : book_eday
            const range_max_days =
                currentClockDuration >= startClockDuration
                    ? book_sday
                    : book_sday - 1

            if (range_min_days <= range_max_days) {
                info.periods.push({
                    ...item,
                    detail,
                    range_min_days,
                    range_max_days,
                    range_min_date: moment()
                        .add(range_min_days, 'd')
                        .format('YYYY-MM-DD'),
                    range_max_date: moment()
                        .add(range_max_days, 'd')
                        .format('YYYY-MM-DD')
                })
            }
        }

        return info
    })
}

const getReservation = ({ shopid }) => {
    return perfect({
        url: 'v1/shop/bookset_info',
        data: {
            params: { shopid }
        }
    }).then((response) => response.info)
}

const reserveBox = ({
    memberid,
    customerid,
    shopid,
    stallid,
    merchant,
    number,
    part,
    remark = '',
    booktime,
    amount
}) => {
    return perfect({
        url: 'v1/box/createorder',
        data: {
            params: {
                memberid,
                customerid,
                shopid,
                stallid,
                merchant,
                number,
                part,
                remark,
                booktime,
                amount
            }
        },
        transformResponse: [
            (response) => {
                response = JSON.parse(response)

                const { code, msg, seq } = response.data || {}

                return {
                    code:
                        response.success && code === 1
                            ? 1
                            : response.errCode || code,
                    list: [],
                    total: 0,
                    info: {
                        seq
                    },
                    msg: response.errMessage || msg
                }
            }
        ]
    }).then((response) => response.info)
}

const getCabinets = ({ date, stall_id, part, orderway }) => {
    return perfect({
        url: 'v1/ordering/shop_cabinetlate',
        data: {
            params: {
                date,
                stall_id,
                part,
                orderway
            }
        }
    }).then((response) => response.list)
}

const getTable = ({ id }) =>
    perfect({
        url: 'v1/table/infodishtable',
        data: {
            params: {
                id
            }
        }
    }).then((response) => response.info)

const getGatewayPaymentTypes = ({ id }) =>
    perfect({
        url: `v1/ordering/vegaAccounts`,
        data: {
            params: {
                id
            }
        }
    }).then((response) => response.list)

export default {
    getCanteen,
    getGateway,
    getPeriod,
    getPeriods,
    getDatum,
    addReport,
    getGatewayReservation,
    getReservation,
    reserveBox,
    getCabinets,
    getTable,
    getGatewayPaymentTypes
}
