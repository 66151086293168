import axios from 'axios'

// 获取飞书用户手机号码
const getFeishuAuthPhone = function ({ code }) {
    return axios
        .post('/sovell/custom/api/v3/feishu/user/info', {
            code
        })
        .then(({ data: { data: info } }) => {
            const { mobile = null } = info
            return mobile
        })
}

export default {
    getFeishuAuthPhone
}
