import perfect from '@/library/request/perfect'

const getUser = () => {
    return perfect({
        url: `v3/user`,
        method: 'GET'
    }).then((response) => response.info)
}

const changer = (appID) => {
    return perfect({
        url: `v3/user/show/config/${appID}`,
        method: 'GET'
    }).then((response) => response.info)
}

const getBanners = ({ page, rows, name, state, isDate } = {}) => {
    return perfect({
        url: 'v1/setting/listbanner',
        data: {
            params: {
                termtype: 'palmtop',
                page,
                rows,
                name,
                state,
                isDate
            }
        },
        useToken: false
    }).then((response) => {
        response.list = response.list.map((item) => {
            item.pic = item.pic ? JSON.parse(item.pic) : []

            return item
        })

        return response
    })
}

const getBanner = ({ id }) => {
    return perfect({
        url: 'v1/setting/infobanner',
        data: {
            params: {
                id
            }
        }
    }).then((response) => {
        response.info.pic = JSON.parse(response.info.pic)

        return response
    })
}

export default {
    getUser,
    changer,
    getBanners,
    getBanner
}
