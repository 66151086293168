import '@/css/default.less'
import '@/css/custom.less'
import '@/font/iconfont.css'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import app from '@/page/index/index'
import routes from './route'
import 'core-js'
import stores from '@/store/index'
import { updateHtmlSize } from '@/library/wrench'
import mobileEvent from '@/library/mobileEvent'
import loading from '@/plugins/loading/index.js'
import toast from '@/plugins/toast/index.js'
import modal from '@/plugins/modal/index.js'
import api from '@/api'
import image from '@/component/image'
import button from '@/component/button'
import empty from '@/component/empty'
import drawer from '@/component/drawer'
import curtain from '@/component/curtain'
import modal2 from '@/component/modal'
import { DatetimePicker } from 'vant'
import 'vant/lib/index.css'
import VConsole from 'vconsole'
import request from '@/library/request/perfect'

// 拦截跳转地址中拼接的 appid，用来请求 `v3/user/show/config/${appID}` 接口
if (location.search) {
    let query = location.search.replace('?', '')
    let queryList = query.split('&')
    for (let i = 0; i < queryList.length; i++) {
        if (queryList[i].split('=')[0] == 'wechatappid') {
            localStorage.setItem(
                'PalmPilotH5WechatAppId',
                queryList[i].split('=')[1]
            )
        }
        if (queryList[i].split('=')[0] == 'agentid') {
            localStorage.setItem(
                'PalmPilotH5WechatWorkAgentId',
                queryList[i].split('=')[1]
            )
        }
    }
}

new VConsole()

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(loading)
Vue.use(toast)
Vue.use(modal)
Vue.use(DatetimePicker)
mobileEvent(Vue)

Vue.prototype.$wx = window.wx

console.log('=> process.env.BASE_URL <=', process.env.BASE_URL)

const router = new VueRouter({
    mode: 'history', // 项目前期开发一直都是使用 history 模式，不要改成 hash
    base: process.env.BASE_URL,
    routes
})

const store = new Vuex.Store(stores)
window.$store = store

router.beforeResolve(async (to, from, next) => {
    document.title = store.state.currentTitle = to.meta.title || '掌上通'

    // iOS 设备使用第一次进入 App 时的 URL 去请求 wxConfig, 微信浏览器扫一扫
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
        window.entryUrl = location.href.split('#')[0]
        console.log('window.entryUrl', window.entryUrl)
    }

    if (/^\/feishu\/auth/.test(to.path)) {
        next() // 飞书授权页
    } else if (/^\/wechat\/bindUser/.test(to.path)) {
        next()
    } else {
        if (store.state.token) {
            if (location.search.includes('token')) {
                // 如果跳转链接包含“token”关键字
                const new_token = decodeURIComponent(
                    location.search.split('&')[1].split('=')[1]
                )
                const old_token = store.state.token
                console.log('new_token', new_token)
                console.log('old_token', old_token)
                if (new_token != old_token) {
                    // new_token 和 old_token 不一致
                    // 清除缓存
                    store.commit('deleteLocalStorage', 'token')
                    store.commit('deleteSessionStorage', 'user')
                    store.commit('deleteSessionStorage', 'bindUser')
                    // 保存新token
                    store.commit('updateLocalStorage', {
                        key: 'token',
                        value: new_token
                    })
                    // 跳转到判断首页版本的页面，最终完成跳转到对应的首页
                    next({ path: '/index' })
                } else {
                    if (!store.state.user) {
                        const user = await api.home.getUser()

                        store.commit('updateSessionStorage', {
                            key: 'user',
                            value: user
                        })
                    }

                    if (!store.state.bindUser) {
                        const bindUser = await api.login
                            .getBindUsers()
                            .then((response) => response[0])

                        store.commit('updateSessionStorage', {
                            key: 'bindUser',
                            value: bindUser
                        })
                    }

                    if (!(store.state.user && store.state.bindUser)) {
                        store.commit('clearSessionStorage')
                        store.commit('clearLocalStorage')

                        if (!/^\/login/.test(to.path)) {
                            next({
                                path: '/login',
                                query: from.query
                            })
                        } else {
                            next()
                        }
                    } else if (/^\/login/.test(to.path)) {
                        next({
                            path: '/index',
                            query: from.query
                        })
                    } else {
                        next()
                    }
                }
            } else {
                if (!store.state.user) {
                    const user = await api.home.getUser()
                    console.log('=> router getUser <=', user)

                    store.commit('updateSessionStorage', {
                        key: 'user',
                        value: user
                    })
                }

                if (!store.state.bindUser) {
                    const bindUser = await api.login
                        .getBindUsers()
                        .then((response) => response[0])
                    console.log('=> router getBindUsers <=', bindUser)

                    store.commit('updateSessionStorage', {
                        key: 'bindUser',
                        value: bindUser
                    })
                }

                if (!(store.state.user && store.state.bindUser)) {
                    store.commit('clearSessionStorage')
                    store.commit('clearLocalStorage')
                    console.log('=> router clearLocalStorage <=', to.path)

                    if (!/^\/login/.test(to.path)) {
                        console.log('=> router next login <=')
                        next({
                            path: '/login',
                            query: from.query
                        })
                    } else {
                        next()
                    }
                } else if (/^\/login/.test(to.path)) {
                    next({
                        path: '/index',
                        query: from.query
                    })
                } else {
                    next()
                }
            }
        } else if (!/^\/login/.test(to.path)) {
            console.log('跳转链接search值', location.search)
            if (location.search.includes('token')) {
                // 如果跳转链接包含“token”关键字
                const token = decodeURIComponent(
                    location.search.split('&')[1].split('=')[1]
                )
                console.log('token', token)

                // 保存新token
                store.commit('updateLocalStorage', {
                    key: 'token',
                    value: token
                })
                // 跳转到判断首页版本的页面，最终完成跳转到对应的首页
                next({ path: '/index' })
            } else {
                next({
                    path: '/login',
                    query: from.query
                })
            }
        } else {
            next()
        }
    }
})

Vue.component('app-image', image)
Vue.component('app-button', button)
Vue.component('app-empty', empty)
Vue.component('app-drawer', drawer)
Vue.component('app-modal', modal2)
Vue.component('app-curtain', curtain)

new Vue({
    router,
    store,
    beforeCreate() {
        this.$store.commit('updateLocalStorage', 'token')
        this.$store.commit('updateSessionStorage', 'user')
        this.$store.commit('updateSessionStorage', 'bindUser')
        this.$store.commit('updateSessionStorage', 'type')

        // 设置token请求头
        request.interceptors.request.use((config) => {
            if (config.useToken) {
                config.headers[
                    'authorization'
                ] = `Bearer ${this.$store.state.token}`
            }

            return config
        })
        // 设置请求错误处理
        request.interceptors.response.use(null, (error) => {
            // 判断接口是否响应
            const { code, config, response, data: d } = error
            const data = (response && response.data) || d

            if (config.useErrorTip) {
                let message = '请求失败'

                if (code === 'ERR_CANCELED') {
                    message = '重复请求'
                } else if (code === 'ERR_BAD_RESPONSE') {
                    message = '服务响应异常'
                } else if (data) {
                    message = data.msg
                }

                this.$showToast({
                    title: message
                })
            }

            // token过期的处理
            if (data && data.code === 'token_incorrect_format') {
                this.$store.commit('clearSessionStorage')
                this.$store.commit('clearLocalStorage')
                this.$router.replace({
                    path: '/login'
                })
            }

            return Promise.reject(error)
        })

        let timer = null
        window.addEventListener(
            'resize',
            () => {
                clearTimeout(timer)
                timer = setTimeout(updateHtmlSize, 300)
            },
            false
        )

        updateHtmlSize()
    },
    template: '<app />',
    components: { app }
}).$mount('#app')
