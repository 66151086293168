import moment from 'moment'
import perfect from '@/library/request/perfect'

const getCanteens = ({ type, page = 1, rows, longitude = 0, latitude = 0 }) => {
    return perfect({
        url: 'v1/shop/shop_meal',
        data: {
            params: {
                type,
                page,
                rows,
                longitude,
                latitude
            }
        }
    })
}

const getPraiseGateways = ({ shopId }) => {
    return perfect({
        url: 'v1/shop/shop_stall_state',
        data: {
            params: {
                shopId
            }
        }
    }).then((response) => response.list)
}

const getGateways = ({ page = 1, rows = 10, shopid, state, mode } = {}) => {
    return perfect({
        url: 'v1/stall/list',
        data: {
            params: {
                page,
                rows,
                shopid,
                state,
                mode: mode === 15 ? undefined : mode,
                typeid: mode === 15 ? 15 : undefined
            }
        }
    })
}

const getData = ({ stall, date, times }) => {
    return perfect({
        url: 'v1/shop/subscribe_schedule',
        data: {
            params: {
                stall,
                date,
                times
            }
        },
        multiple: true
    }).then((response) => {
        const { meal_list = [], prod_list = [] } = response.list[0] || {}

        return {
            meal_list: meal_list.map((item) => {
                item.price_name = item.price.toFixed(2)
                item.policy_type = 21

                return item
            }),
            prod_list: prod_list
                .map(({ prodlist }) => prodlist)
                .flat(1)
                .map((item) => {
                    item.price_name = item.price.toFixed(2)
                    item.policy_type = 21

                    return item
                })
        }
    })
}

const getMeals = ({ stall, date, times }) => {
    return perfect({
        url: 'v1/shop/schedule',
        data: {
            params: {
                stall,
                date,
                times
            }
        }
    }).then((response) => {
        const { meal_list, prod_list } = response.list[0] || {}

        return {
            meal_list: meal_list
                .map(({ prodlist }) => prodlist)
                .flat(1)
                .map((item) => {
                    item.price_name = item.price.toFixed(2)
                    item.policy_type = 1

                    return item
                }),
            prod_list: prod_list
                .map(({ prodlist }) => prodlist)
                .flat(1)
                .map((item) => {
                    item.price_name = item.price.toFixed(2)
                    item.policy_type = 1

                    return item
                })
        }
    })
}

const getMeal = ({ id }) => {
    return perfect({
        url: 'v1/prod/info_prod',
        data: {
            params: {
                id
            }
        }
    }).then((response) => {
        const { info, specs } = response
        const { gage_dish, gage_price, gage_copy_dish, gage_copy_price } = info

        info.weights = []

        if (gage_copy_dish === 1) {
            info.weights.push({
                type: 1,
                price: gage_copy_price,
                type_name: '按份数（元/份）'
            })
        }
        if (gage_dish === 1) {
            info.weights.push({
                type: 2,
                price: gage_price,
                type_name: '按重量（元/100g）'
            })
        }

        info.labs_allergy_array = info.labs_allergy
            ? info.labs_allergy.split(',')
            : []
        info.labs_careful_array = info.labs_careful
            ? info.labs_careful.split(',')
            : []
        info.labs_nutrition_array = info.labs_nutrition
            ? info.labs_nutrition.split(',')
            : []

        info.specs = specs
        delete response.specs

        return response
    })
}

const getTypes = ({ merchant }) => {
    return perfect({
        url: 'v1/prod/list_cate',
        data: {
            params: {
                merchant,
                page: 1,
                rows: 10000,
                parent: 0,
                state: 1
            }
        }
    })
}

const getPeriods = ({
    shopid,
    state,
    name = '',
    page = 1,
    rows = 8 // 餐别只有8个
} = {}) => {
    return perfect({
        url: 'v1/shop/list_hours',
        data: {
            params: {
                shopid,
                state,
                name,
                page,
                rows
            }
        }
    }).then((response) => {
        response.list = response.list
            .sort(
                (a, b) =>
                    moment(a.stime, 'HH:mm').valueOf() -
                    moment(b.stime, 'HH:mm').valueOf()
            )
            .map((item) => {
                item.id = item.autoid

                delete item.autoid
                return item
            })

        return response
    })
}

const getSetMeals = ({ stall, date, times }) => {
    return perfect({
        url: 'v1/shop/subscribe_meal_schedule',
        data: {
            params: {
                stall,
                date,
                times
            }
        }
    }).then((response) => {
        response.list = response.list[0]
            ? response.list[0].meal_list.map((item) => {
                  item.price_name = item.price.toFixed(2)
                  item.policy_type = 3

                  return item
              })
            : []

        return response
    })
}

export default {
    getCanteens,
    getPraiseGateways,
    getGateways,
    getData,
    getMeals,
    getMeal,
    getTypes,
    getPeriods,
    getSetMeals
}
