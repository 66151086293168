<template>
    <div :class="$style.container">
        <template v-for="(item, index) in data">
            <div
                :class="`navigator ${activeKey === item.key ? 'active' : ''}`"
                :key="index"
                v-if="item.key !== 'cart'"
                @click="skipPage(item)"
            >
                <img
                    class="navigator__image"
                    :src="
                        activeKey === item.key ? item.activeImage : item.image
                    "
                />
                <div class="navigator__name">{{ item.name }}</div>
            </div>

            <div
                class="navigator navigator--cart"
                :key="index"
                v-else
                @click="skipPage(item)"
            >
                <div class="navigator__icon iconfont icon-cart-2"></div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: [
                {
                    image: require('@/image/home-1-1.png'),
                    activeImage: require('@/image/home-1-2.png'),
                    key: 'home',
                    paths: ['/home/palm-pilot'],
                    name: '首页'
                },
                {
                    image: require('@/image/message-1-1.png'),
                    activeImage: require('@/image/message-1-2.png'),
                    key: 'message',
                    paths: ['/page/navi/message/messagehelper'],
                    name: '消息'
                },
                {
                    image: require('@/image/order-1-1.png'),
                    activeImage: require('@/image/order-1-2.png'),
                    key: 'order',
                    paths: ['/order'],
                    name: '订单'
                },
                {
                    image: require('@/image/member-1-1.png'),
                    activeImage: require('@/image/member-1-2.png'),
                    key: 'member',
                    paths: ['/member'],
                    name: '我的'
                }
            ]
        }
    },
    computed: {
        activeKey() {
            const target = this.data.find((item) => {
                return item.paths.some((path) => path === this.$route.path)
            })

            return target ? target.key : ''
        }
    },
    methods: {
        skipPage(item) {
            if (this.activeKey !== item.key) {
                this.$router.replace({
                    path: item.paths[0]
                })
            }
        }
    },
    created() {
        const type = this.$store.state.type

        this.data[0].paths =
        type === 2
                ? ['/home/school']
                : type === 3
                ? ['/home/praise']
                : ['/home/palm-pilot']
    }
}
</script>

<style module>
.container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    width: 100%;
    padding: 0 20px;
    background: #fff;
}
</style>

<style lang="less" scoped>
.navigator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 28px;
    &.active .navigator__name {
        color: #ff7500;
    }
}
.navigator--cart {
    width: 152px;
    height: 152px;
    padding: 20px;
    margin-top: -30px;
    background: #fff;
    border-radius: 50%;
    .navigator__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 65px;
        background: linear-gradient(42deg, #ff7500 0%, #ff9f4e 100%);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
        border-radius: 50%;
    }
}

.navigator__image {
    width: 48px;
    height: 48px;
}
.navigator__name {
    margin-top: 8px;
    color: #666;
    text-align: center;
    font-size: 24px;
    line-height: 1;
}
</style>
