<template>
    <div :class="$style.container">
        <navigation
            class="navigation"
            :title="title"
            :showBackButton="showBackButton"
            :style="navigationStyle"
            v-if="showNavigation"
        />
        <div class="main">
            <router-view />
        </div>
        <tab class="tab" v-if="showTab" />
    </div>
</template>

<script>
import navigation from './navigation'
import tab from './tab'

export default {
    computed: {
        title() {
            return this.$store.state.currentTitle || ''
        },
        showNavigation() {
            return this.$route.meta.showNavigation === undefined
                ? true
                : this.$route.meta.showNavigation
        },
        showBackButton() {
            return this.$route.meta.showBackButton === undefined
                ? true
                : this.$route.meta.showBackButton
        },
        showTab() {
            return this.$route.meta.showTab || false
        },
        navigationStyle() {
            return this.$route.meta.navigationStyle
        }
    },
    components: { navigation, tab }
}
</script>

<style module>
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
</style>

<style lang="less" scoped>
.navigation {
    flex-shrink: 0;
}
.main {
    flex-grow: 1;
    width: 100%;
    height: 0;
    overflow-y: auto;
}
.tab {
    flex-shrink: 0;
}
</style>
