import perfect from '@/library/request/perfect'
import moment from 'moment'
import { keyMerge } from '@/library/wrench'

const getTypes = () => {
    return perfect({
        url: 'v1/member/list_membertype',
        data: {
            params: {}
        }
    }).then((response) => {
        response.list = response.list.map(({ id, name, remark, role }) => {
            const remarks = remark.split(',')

            return {
                id,
                name,
                data: role.split(',').map((v, i) => ({
                    label: remarks[i],
                    value: Number(v)
                }))
            }
        })

        return response
    })
}

const setPhone = ({ customerId, newPhoneNumber, smsCode }) => {
    return perfect({
        url: 'v3/customer/change/phone',
        data: {
            customerId,
            newPhoneNumber,
            smsCode,
            type: 'customer_change_phone'
        }
    })
}

const getPhysiques = ({ customerId, pageNum, pageSize }) => {
    return perfect({
        url: 'v3/customer/body/weight/history',
        data: {
            customerId,
            pageNum,
            pageSize
        }
    }).then((response) => {
        response.list = response.list.map((item) => {
            item.createDateName = moment(
                item.createDate,
                'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY年MM月DD日')

            return item
        })

        return response
    })
}

const setWeightOrBMI = ({ id, date, weight, bmi }) => {
    return perfect({
        url: 'v1/mobile/weight_record',
        data: {
            params: {
                id,
                date,
                weight,
                bmi
            }
        }
    })
}

const setTargetWeightOrBMI = ({ id, type, targetweight, targetbmi }) => {
    return perfect({
        url: 'v1/mobile/weightorbmiset',
        data: {
            params: {
                id,
                type,
                targetweight: targetweight ? targetweight * 1 : targetweight,
                targetbmi: targetbmi ? targetbmi * 1 : targetbmi
            }
        }
    })
}

const getNutrition = ({ id, sdate, edate, part } = {}) => {
    return perfect({
        url: 'v1/nutrition/reportmanydays',
        data: {
            params: {
                id,
                sdate,
                edate,
                part
            }
        }
    }).then((response) => {
        response.list.foods = response.list.foods.map((item) => {
            item.list = keyMerge(item.list, ['part', 'partname']).map(
                ({ map, data: meals }) => ({
                    part: map.part,
                    partname: map.partname,
                    meals
                })
            )

            return item
        })

        return response
    })
}

export default {
    getTypes,
    setPhone,
    getPhysiques,
    setWeightOrBMI,
    setTargetWeightOrBMI,
    getNutrition
}
