import request from '@/library/request/controller'
import moment from 'moment'
const state = {
    messageTradeTypeOptions: [
        {
            id: 'pay',
            name: '消费'
        },
        {
            id: 'refund',
            name: '消费撤单'
        },
        {
            id: 'charge',
            name: '充值'
        },
        {
            id: 'charge_refund',
            name: '充值退款'
        },
        {
            id: 'subtract',
            name: '余额减值'
        },
        {
            id: 'credit_repayment',
            name: '授信还款'
        },
        {
            id: 'offline_repayment',
            name: '离线还款'
        },
        {
            id: 'transfer',
            name: '转账'
        },
        {
            id: 'subsidy_add',
            name: '补贴下发'
        }
    ]
}

// getters
const getters = {}

// actions
const actions = {
    async getTimeDesc({ state, commit }, { payload }) {
        var timeLong = new Date().valueOf() - payload.time
        timeLong = timeLong > 0 ? timeLong : 1
        var hours = timeLong / 1000 / 60 / 60
        var min = (timeLong - Math.floor(hours) * 60 * 60 * 1000) / 1000 / 60
        if (hours < 1) {
            return Math.ceil(min) + '分钟前'
        } else {
            if (hours < 24) {
                return Math.ceil(hours) + '小时前'
            } else {
                if (hours < 24 * 30) {
                    return Math.floor(hours / 24) + '天前'
                } else {
                    if (
                        payload.moment(new Date().valueOf(), 'YYYY') ==
                        payload.moment(payload.time, 'YYYY')
                    ) {
                        return payload.moment(payload.time, 'MM-DD')
                    } else {
                        return payload.moment(payload.time, 'YYYY-MM-DD')
                    }
                }
            }
        }
    },
    async getCustomerNoticList({ state, commit }, { payload }) {
        const notice = await request({
            url: 'v1/community/notice',
            method: 'POST',
            data: payload.reqData
        }).then((response) => {
            response.list = response.list.map((item) => {
                item.type = 'notice'
                return item
            })
            return response
        })
        return request({
            url: 'v1/question/list_question',
            data: {
                params: {
                    page: payload.reqData.page,
                    rows: payload.reqData.rows,
                    memberid: payload.reqData.member
                }
            }
        }).then((response) => {
            const list = response.list.map((item) => {
                item.type = 'question'
                item.title = item.name
                item.body = item.explain
                return item
            })

            return {
                ...response,
                list: [...notice.list, ...list]
                    .sort((a, b) =>
                        moment(b.create_date).diff(
                            moment(a.create_date),
                            'seconds'
                        )
                    )
                    .slice(0, payload.reqData.rows),
                total: notice.total + response.total,
                number: notice.number + response.number
            }
        })
    },
    async getQuestion({ state, commit }, { payload }) {
        const response = await request({
            url: 'v1/question/info_question',
            data: {
                params: payload.reqData
            }
        }).then((response) => {
            const { subject } = response.info

            response.info.subject = JSON.parse(subject)

            return response
        })

        const answers = await request({
            url: 'v1/question/memberquestion',
            data: {
                params: {
                    memberid: payload.userId,
                    qid: payload.reqData.id
                }
            }
        })
            .then((response) => {
                return response.subject.filter(
                    (item) => item.subject_option_member.length > 0
                ).length > 0
                    ? response.subject
                    : []
            })
            .catch((_) => [])

        response.info.answers = answers
        response.info.isread = answers.length > 0 ? 1 : 0

        return response
    },
    async answerQuestions({ state, commit }, { payload }) {
        return await request({
            url: 'v1/question/mobilequestionsubmit',
            data: {
                params: payload.reqData
            }
        })
    },
    async getNotice({ state, commit }, { payload }) {
        return await request({
            url: 'v1/notice/info',
            data: {
                params: payload.reqData
            }
        })
    },
    async setReadNotices({ state, commit }, { payload }) {
        return await request({
            url: 'v1/community/readnotice',
            data: {
                params: payload.reqData
            }
        })
    },
    async editUserInfo({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer',
            method: 'PUT',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCustomerMessageList({ state, commit }, { payload }) {
        const data = await request({
            baseURL:
                window.location.origin +
                '/atlantis/api/devops/clientapi/msg/detail/query/page',
            url: '',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getBillInfo({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/bill/account/detail',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getBillTotal({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/detail/bill/query/total',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getBillList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/detail/bill/query/page',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async checkCharge({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/id/' + payload.tarPars.id,
            data: payload.reqData
        })
        console.log(data)
        return {
            data: data
        }
    },
    async createCharge({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/charge/customer',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCustomerEnterpriseInfo({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/enterprise/account/query',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async checkPayCode({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/authCode/' + payload.tarPars.code,
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getPayCode({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/authCode',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getVoucherInfo({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/policy/' + payload.tarPars.id,
            method: 'GET',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCouponCusList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/coupon/query/page',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async addVoucher({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/policy/guest/meal/voucher/auth',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getVoucherList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/policy/query/page',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getUserList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/customer/query/page',
            data: {}
        })

        return {
            data: data
        }
    },
    async chargeCheck({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/trade/charge/check',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCardList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/card/query/page',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async changeCardStatus({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/card/' + payload.tarPars.type,
            method: 'PUT',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async disableCoupon({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/coupon/disable',
            method: 'PUT',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCouponList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/coupon/list',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getOverCouponList({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/coupon/history',
            data: payload.reqData
        })
        return {
            data: data
        }
    },
    async getCoupon({ state, commit }, { payload }) {
        const data = await request({
            url: 'v3/customer/coupon/' + payload.tarPars.id,
            method: 'GET',
            data: payload.reqData
        })
        return {
            data: data
        }
    }
}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
