<template>
    <div
        :class="$style.container"
        v-if="value"
        @click.self="$emit('input', false)"
    >
        <div :class="$style.inner">
            <div :class="$style.header">
                {{ title }}
                <div
                    :class="`${$style['close-button']} iconfont icon-close-2`"
                    @click="$emit('input', false)"
                ></div>
            </div>
            <div :class="$style.content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        }
    },
    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.$emit('open')
                } else {
                    this.$emit('close')
                }
            }
        }
    }
}
</script>

<style module>
.container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}
.inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 32px 32px 0 0;
}
.header {
    position: relative;
    text-align: center;
    height: 96px;
    padding: 0 60px;
    color: #1a1a1a;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 96px;
    border-bottom: 2px solid #f2f2f2;
}
.content {
    max-height: 70vh;
    overflow-y: auto;
}
.close-button {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 2;
    width: 60px;
    height: 60px;
    color: #999;
    font-size: 45px;
    line-height: 60px;
    text-align: center;
}
</style>
